<template>
  <div class="req-temp-container">
    <vs-card class="mt-6" v-if="portalBind">
      <div slot="header">
        <h3 class="font-normal">Branding</h3>
      </div>
      <vs-row>
        <vs-row>
          <vs-col class="lg:mr-5 md:mr-5 sm:mr-5 w-auto">
            <div
              class="radio-card my-5"
              @click="activate(true)"
              :class="{ active: active_el == true }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Default
                </vs-col>
                <vs-col
                  vs-w="2"
                  vs-sm="2"
                  vs-lg="2"
                  vs-xs="2"
                  class="flex justify-center"
                >
                  <vs-radio
                    v-model="defaultHeader"
                    vs-value="1"
                    vs-name="header"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">
                    Use branding details from Settings > Branding & style</span
                  >
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <vs-col class="w-auto">
            <div
              class="radio-card my-5"
              @click="activate(false)"
              :class="{ active: active_el == false }"
            >
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  Custom
                </vs-col>
                <vs-col
                  vs-w="2"
                  vs-sm="2"
                  vs-lg="2"
                  vs-xs="2"
                  class="flex justify-center"
                >
                  <vs-radio
                    v-model="defaultHeader"
                    vs-value="0"
                    vs-name="header"
                  >
                  </vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info"> Configure custom branding</span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <vs-row>
          <!-- <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
            <hr />
          </vs-col> -->
        </vs-row>
        <vs-row :class="{ disabled: headerDisabled == 0 }">
          <vs-col vs-w="12" vs-lg="12" vs-sm="12">
            <h4 class="mb-4 mt-10 font-normal">Logo</h4>
            <hr />
          </vs-col>

          <vs-row>
            <p class="mt-5 mb-5">
              Logo will appear at the top of the page when customers log in to manage their payments.
            </p>
            <div class="w-full">
              <template v-if="image">
                <!-- Image Container -->
                <div class="flex">
                  <div class="company-logo-wrap relative">
                    <img :src="image" alt="img" class="responsive" />
                    <span  class="XCircleIcon">
                      <feather-icon
                    icon="XCircleIcon"
                   v-if="!defaultHeader"
                    @click="clearImage"
                  />
                    </span>

                  </div>

                </div>

                <!-- Image upload Buttons -->
                <div class="modify-img flex my-8">
                  <input
                    type="file"
                    class="hidden"
                    ref="updateImgInput"
                    @change="updateCurrImg"
                    v-validate="'size:5000'"
                    name="companyLogo"
                    accept="image/png"
                  />
                </div>
              </template>
              <div class="flex upload-img my-5 items-center" v-if="!image">
                <!-- <div class="flex">
                    <div class="company-logo-wrap ">
                      <img
                        src="https://www.solidbackgrounds.com/images/2560x1600/2560x1600-dark-gray-solid-color-background.jpg"
                        alt="img"
                        class="responsive"
                      />
                    </div>
                  </div> -->
                <input
                  type="file"
                  name="companyLogo"
                  v-validate="'size:5000'"
                  class="hidden"
                  ref="uploadImgInput"
                  @change="updateCurrImg"
                  accept="image/png"
                />
                <div class="force-btn-width">
                  <vs-button
                    flat
                    v-round
                    class="only-border-btn upicon"
                    size="medium"
                    type="border"
                    @click="$refs.uploadImgInput.click()"
                  >
                    <template>
                      <upload-icon
                        size="1.5x"
                        class="custom-class"
                      ></upload-icon
                    ></template>
                    <u>Upload Image</u></vs-button
                  >

                  <div class="mt-5 mb-5 flex justify-center">
                    <span  class="text-base">Or</span>
                  </div>
                  <vs-button
                    flat
                    v-round
                    class="only-border-btn upicon"
                    size="medium"
                    type="border"
                    @click="popupActivo = true"
                  >
                    <template>
                      <folder-icon
                        size="1.5x"
                        class="custom-class"
                      ></folder-icon
                    ></template>
                    <u>Choose From Library</u></vs-button
                  >
                </div>
              </div>
            </div>
          </vs-row>
        </vs-row>
        <form enctype="multipart/form-data">
          <div :class="{ disabled: headerDisabled == 0 }">
            <vs-row>
            <vs-col vs-w="12" vs-lg="12" vs-sm="12">
                <h4 class="mb-4 mt-10 text-xl font-normal">Footer</h4>
                <hr />
                <p class="mt-5 mb-5">
                Footer will appear at the bottom of the page when customers log in to manage their payments.
              </p>
            </vs-col>


              <vs-row class="biz-info">
                <div class="input-grp">
                  <div class="mb-5 mt-5">
                    <label>Business name</label>
                    <vs-input
                      class="w-full"
                      v-validate="'min:3'"
                      size="large"
                      v-model="companyName"
                      name="companyName"
                      id="companyName"
                      :disabled="!headerDisabled"

                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('companyName')"
                      >{{ errors.first("companyName") }}</span
                    >
                  </div>
                </div>
                <div class="input-grp">
                  <div class="mb-5 mt-5">
                    <label>ABN</label>
                    <the-mask
                      :class="
                        abn
                          ? 'vs-inputx vs-input--input large'
                          : 'vs-inputx vs-input--input large'
                      "
                      :mask="['###########']"
                      v-model="abn"
                      masked
                      name="abn"
                      id="abn"
                      ref="abn"
                      v-validate="{
                        required: false,
                        regex: /^\d{11}$/,
                      }"
                      :disabled="!headerDisabled"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('abn')"
                      >{{ errors.first("abn") }}</span
                    >
                  </div>
                </div>
                <div class="input-grp">
                  <div class="mb-5 mt-5">
                    <label>Email</label>
                    <vs-input
                      class="w-full"
                      v-validate="'email'"
                      size="large"
                      v-model="companyEmail"
                      name="companyEmail"
                      id="companyEmail"
                      :disabled="!headerDisabled"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('companyEmail')"
                      >{{ errors.first("companyEmail") }}</span
                    >
                  </div>
                </div>
                <div class="input-grp">
                  <div class="mb-5 mt-5 ">
                  <label>Phone</label>
                    <the-mask
                      :class="
                        phoneNumber
                          ? 'vs-inputx vs-input--input large'
                          : 'vs-inputx vs-input--input large'
                      "
                      :mask="[checkPhoneNumber]"
                      v-model="phoneNumber"
                      masked
                      name="phoneNumber"
                      id="phoneNumber"
                      ref="phoneNumber"
                      v-validate="'phoneFormat'"
                      :disabled="!headerDisabled"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('phoneNumber')"
                      >{{ errors.first("phoneNumber") }}</span
                    >
                  </div>
                </div>
                <div class="input-grp">
                  <div class="mb-5 mt-5">
                    <label>Website</label>
                    <vs-input
                      class="w-full"
                      v-validate="'url|urlFormat'"
                      size="large"
                      v-model="website"
                      name="website"
                      id="website"
                      :disabled="!headerDisabled"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('website')"
                      >{{ errors.first("website") }}</span
                    >
                  </div>
                </div>
                <div class="input-grp">
                  <div class="my-5">
                    <label>Address</label>
                    <vs-input
                      class="w-full"
                      v-validate="''"
                      size="large"
                      v-model="address"
                      name="address"
                      id="address"
                      :disabled="!headerDisabled"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('address')"
                      >{{ errors.first("address") }}</span
                    >
                  </div>
                </div>
              </vs-row>
            </vs-row>
          </div>
          <!-- popup starts -->
          <div class="centerx">
            <vs-popup
              class="holamundo"
              title="Logo Library"
              :active.sync="popupActivo"
            >
              <vs-row>
                <div
                  @click="clickLogo(logo)"
                  v-for="logo in logoLibrary"
                  :key="logo.logoId"
                >
                  <vs-col vs-w="4" vs-lg="4" vs-sm="12">
                    <img class="selectLogo" :src="logo.logoImage" />
                  </vs-col>
                </div>
              </vs-row>
            </vs-popup>
          </div>

          <!-- popup ends -->
        </form>
      </vs-row>
    </vs-card>

    <leave-popup
      :popup-active="popupActive"
      @handleClick="popActions"
    ></leave-popup>

    <div class="flex justify-end mt-5" v-if="errors.items.length > 0">
      <!--- validation is displayed here, userform is name of the form--->

      <pop-up :errorsList="errors" form-name="CustomerPortalForm"></pop-up>
    </div>

    <div class="button-section">
      <vs-button
        :disabled="!validateForm"
        size="large"
        @click="save"
        v-round
        >Save</vs-button
      >
      <div class="cancel-btn" @click="onCancel"><u>Cancel</u></div>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { UploadIcon } from "vue-feather-icons";
import { FolderIcon } from "vue-feather-icons";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";

const dictionary = {
  en: {
    attributes: {
      companyName: "business name",
      abn: "abn",
      companyEmail: "email address",
      phoneNumber: "phone number",
      website: "website",
      address: "address",
      companyLogo: "logo",
    },
  },
};
Validator.localize(dictionary);
export default {
  components: { TheMask, UploadIcon, FolderIcon, PopUp, LeavePopup },
  data() {
    return {
      popupActivo: false,
      merchantId: "",
      saveDisabled: true,
      defaultHeader: "",
      companyDefaultHeader: {},
      companyCustomHeader: {},
      companyName: "",
      abn: "",
      companyEmail: "",
      phoneNumber: "",
      website: "",
      address: "",
      image: "",
      logoLibrary: [],
      active_el: "",
      headerDisabled: "",
      partnerId: "",
      imageUrl: `${process.env.VUE_APP_API_URL}uploads/merchantlogo/`,
      companyLogo: "",
      portalBind: false,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
      childFormDirty: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    checkPhoneNumber(){
      let mask = '#### ### ###';
      if(this.phoneNumber && this.phoneNumber.length > 0){
        let numLength = this.phoneNumber.length;
        let value1 = '';
        let value2 = '';
        if(numLength >= 2){
          value1 = this.phoneNumber.substring(0,2);
          if(value1 === '13'){
            value2 = this.phoneNumber.substring(0,4);
          }
        }
        if(value1 === '02' || value1 === '03' || value1 === '07' || value1 === '08'){
          mask = "## #### ####"
        }else{
          mask = "#### ### ###"
        }

        if(value1 === '13'){
          if(parseInt(value2) >= 1300 || value2 === '130' || value2 === '13 0'){
            mask = "#### ### ###"
          }else{
            mask = "## ## ##"
          }
        }
      }
      return mask;
    }
  },
  mounted() {
    this.merchantId =
      JSON.parse(localStorage.user).userType == "admin"
        ? JSON.parse(localStorage.user).merchantId
        : JSON.parse(localStorage.user).partner.merchantId;
    this.getPartnerId();
    this.getGlobalSetting();
    // this.getPortalSetting();
    this.getLogos();
  },
  methods: {
    ...mapActions("customerPortalSetting", [
      "fetchPortalSettingsByMerchantId",
      "updatePortalSetting",
    ]),
    ...mapActions("merchantGlobalSetting", ["fetchSettingsByMerchantId"]),
    ...mapActions("logo", ["fetchLogosByMerchantId"]),
    onCancel() {
      this.$router.push({ name: "settings" });
    },
    async save() {
      try {
        const valid = await this.$validator.validate();
        if (!valid) {
          return false;
        }

        const formData = new FormData();
        formData.append("uploadLogo", this.uploadLogo);
        formData.set("companyName", this.companyName);
        formData.set("abn", this.abn);
        formData.set("companyEmail", this.companyEmail);
        formData.set("phone", this.phoneNumber);
        formData.set("website", this.website);
        formData.set("address", this.address);
        formData.set("isDefaultHeader", this.defaultHeader);
        if (!this.uploadLogo) {
          formData.set("companyLogo", this.companyLogo);
        }
        const data = {
          id: this.partnerId,
          payload: formData,
        };
        this.$vs.loading();
        this.updatePortalSetting(data)
          .then((result) => {
            this.$vs.loading.close();
            this.popupActive = false;
            this.$vs.notify({
              title: "Customer Portal",
              text: result.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.isSaved = true;
            this.$router.push({ name: "settings" });
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Customer Portal",
              text: err.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } catch (err) {
        
      }
    },
    getPartnerId() {
      this.partnerId = JSON.parse(localStorage.getItem("user"))._id;
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.uploadLogo = input.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    activate(el) {
      this.active_el = el;
      this.defaultHeader = el;
      if (this.defaultHeader == false) {
        //custom header
        this.headerDisabled = 1;
        if (
          this.companyCustomHeader &&
          this.companyCustomHeader.isDefaultHeader == false
        ) {
          this.companyName = this.companyCustomHeader.companyName;
          this.abn = this.companyCustomHeader.abn;
          this.companyEmail = this.companyCustomHeader.companyEmail;
          this.phoneNumber = this.companyCustomHeader.phone;
          this.website = this.companyCustomHeader.website;
          this.address = this.companyCustomHeader.address;
          if(this.companyCustomHeader.companyLogo ){
            this.companyLogo = this.companyCustomHeader.companyLogo._id;
            this.image =
            this.imageUrl + this.companyCustomHeader.companyLogo.logoImage;
          }else{
            this.image = null;
            this.companyLogo = null;
          }

        } else {
          this.companyName = "";
          this.abn = "";
          this.companyEmail = "";
          this.phoneNumber = "";
          this.website = "";
          this.address = "";
          this.companyLogo = "";
          this.image = "";
        }
      } else {
        //company default header
        this.headerDisabled = 0;
        this.companyName = this.companyDefaultHeader.companyName;
        this.abn = this.companyDefaultHeader.abn;
        this.companyEmail = this.companyDefaultHeader.companyEmail;
        this.phoneNumber = this.companyDefaultHeader.phone;
        this.website = this.companyDefaultHeader.website;
        this.address = this.companyDefaultHeader.address;
        this.companyLogo = this.companyDefaultHeader.companyLogo._id;
        this.image =
          this.imageUrl + this.companyDefaultHeader.companyLogo.logoImage;
      }
      this.childFormDirty = true;
    },
    getGlobalSetting() {
      this.fetchSettingsByMerchantId().then((res) => {
        this.headerDisabled = 0;
        this.defaultHeader = true;
        this.active_el = true;
        this.companyDefaultHeader = res.data.data.companyHeader ? res.data.data.companyHeader : '';
        this.companyName = res.data.data.companyHeader ? res.data.data.companyHeader.companyName : '';
        this.abn = res.data.data.companyHeader ? res.data.data.companyHeader.abn : '';
        this.companyEmail = res.data.data.companyHeader ? res.data.data.companyHeader.companyEmail : '';
        this.phoneNumber = res.data.data.companyHeader ? res.data.data.companyHeader.phone : '';
        this.website = res.data.data.companyHeader ? res.data.data.companyHeader.website : '';
        this.address = res.data.data.companyHeader ? res.data.data.companyHeader.address : '';
        this.image = res.data.data.companyHeader && res.data.data.companyHeader.companyLogo ?
          this.imageUrl + res.data.data.companyHeader.companyLogo.logoImage : '';
        this.companyLogo = res.data.data.companyHeader && res.data.data.companyHeader.companyLogo ? res.data.data.companyHeader.companyLogo._id : '';
        this.getPortalSetting();
      });
    },
    getPortalSetting() {
      this.fetchPortalSettingsByMerchantId(this.partnerId)
        .then((res) => {
          if(res){
            const checkDefault = res.data.data.isDefaultHeader;
            this.companyCustomHeader = res.data.data.companyHeader;
            this.companyCustomHeader.isDefaultHeader = checkDefault;
            if (checkDefault == false) {
              this.companyName = res.data.data.companyHeader.companyName;
              this.abn = res.data.data.companyHeader.abn;
              this.companyEmail = res.data.data.companyHeader.companyEmail;
              this.phoneNumber = res.data.data.companyHeader.phone;
              this.website = res.data.data.companyHeader.website;
              this.address = res.data.data.companyHeader.address;
              if(res.data.data.companyHeader.companyLogo ){
                this.image =
                  this.imageUrl + res.data.data.companyHeader.companyLogo.logoImage;
                this.companyLogo = res.data.data.companyHeader.companyLogo._id;
              }else{
                this.image = null;
                this.companyLogo = null;
              }
              this.defaultHeader = res.data.data.isDefaultHeader;
              this.active_el = false;
              this.headerDisabled = 1;
            } else {
              this.defaultHeader = true;
              this.active_el = true;
              this.headerDisabled = 0;
            }
          }else{
            this.headerDisabled = 0;
            this.defaultHeader = true;
            this.active_el = true;
            this.companyName = this.companyDefaultHeader.companyName;
            this.abn = this.companyDefaultHeader.abn;
            this.companyEmail = this.companyDefaultHeader.companyEmail;
            this.phoneNumber = this.companyDefaultHeader.phone;
            this.website = this.companyDefaultHeader.website;
            this.address = this.companyDefaultHeader.address;
            this.image =
              this.imageUrl + this.companyDefaultHeader.companyLogo.logoImage;
            this.companyLogo = this.companyDefaultHeader.companyLogo._id;
          }
          this.portalBind = true;
        })
        .catch((err) => {
          this.headerDisabled = 0;
          this.defaultHeader = true;
          this.active_el = true;
          // this.companyName = this.companyDefaultHeader.companyName;
          // this.abn = this.companyDefaultHeader.abn;
          // this.companyEmail = this.companyDefaultHeader.companyEmail;
          // this.phoneNumber = this.companyDefaultHeader.phone;
          // this.website = this.companyDefaultHeader.website;
          // this.address = this.companyDefaultHeader.address;
          // this.image =
          //     this.imageUrl + this.companyDefaultHeader.companyLogo.logoImage;
          // this.companyLogo = this.companyDefaultHeader.companyLogo._id;
          this.portalBind = true;
        });
    },

    getLogos() {
      this.fetchLogosByMerchantId(this.merchantId).then((res) => {
        this.logoLibrary = res.data.data;
      });
    },
    clickLogo(logo) {
      this.companyLogo = logo._id;
      this.image = this.imageUrl + logo.logoImage.split("/").reverse()[0];
      this.popupActivo = false;
      this.childFormDirty = true;
    },
    clearImage() {
      this.companyLogo = null;
      this.image = null;
      this.childFormDirty = true;
    },
    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          break;
        case "save":
          this.popupActive = false;
          // do save the changes
          this.save();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if ((this.isFormDirty || this.childFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
    } else {
      next();
    }
  },
  created() {
    this.$validator.extend("urlFormat", {
      getMessage: () => "The website field is not a valid url.",
      validate: (value) =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null,
    });
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null,
    });
  },
};
</script>